<template>
  <v-container fluid class="copilot-conversations pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="conversations"
          sort-by="startDate"
          :headers="headers"
          :provider="fetchConversations"
          :force-snake-case="false"
          force-url-update
          separate-sort-params
          :filter-fields="copilotFilterFields"
          @change="conversations = $event"
          @click:row="viewConversation"
        >
          <template #item.startDate="{ item }">
            {{ formatDate(item.startDate) }}
          </template>

          <template #item.endDate="{ item }">
            {{ formatDate(item.endDate) }}
          </template>

          <template #item.messagesCount="{ item }">
            <div class="d-flex justify-center align-center">
              {{ item.messagesCount }}
            </div>
          </template>

          <template #item.feedbackCount="{ item }">
            <div class="d-flex justify-center align-center">
              {{ item.feedbackCount }}
            </div>
          </template>

          <template #item.inputTokensCount="{ item }">
            <div
              class="d-flex justify-center align-center"
              :style="{
                color: getColor(item.inputTokensCount, 'inputTokensCount'),
              }"
            >
              {{ item.inputTokensCount }}
            </div>
          </template>

          <template #item.outputTokensCount="{ item }">
            <div
              class="d-flex justify-center align-center"
              :style="{
                color: getColor(item.outputTokensCount, 'outputTokensCount'),
              }"
            >
              {{ item.outputTokensCount }}
            </div>
          </template>

          <template #item.costEstimation="{ item }">
            <div class="d-flex justify-start align-center">
              <span
                v-if="Number(item.costEstimation).toFixed(4) > 0"
                :style="{
                  color: getColor(item.costEstimation, 'costEstimation'),
                }"
              >
                {{ Number(item.costEstimation).toFixed(4) + "&dollar;" }}
              </span>
              <span v-else> - </span>
            </div>
          </template>

          <template #item.duration="{ item }">
            <div class="d-flex justify-center align-center">
              {{ getConversationDuration(item) }}
            </div>
          </template>

          <template #item.cameraExid="{ item }">
            <div v-if="item.cameraExid" class="d-flex align-center">
              <span
                class="cursor-pointer primary--text"
                @click.stop="
                  cameraDialogStore.openDialog({ camera: item.cameraExid })
                "
                >{{ item.cameraExid }}
              </span>
            </div>
            <span v-else>-</span>
          </template>

          <template #item.projectExid="{ item }">
            <div v-if="item.projectExid" class="d-flex align-center">
              <span
                class="cursor-pointer primary--text"
                @click.stop="projectStore.openDialog(item.projectExid)"
              >
                {{ item.projectExid }}
              </span>
            </div>
            <span v-else>-</span>
          </template>

          <template #item.user="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click.stop="openUserDialog(item.user)"
            >
              {{ item.user }}
            </span>
          </template>

          <template #item.model="{ item }">
            {{ item.model }}
          </template>

          <template #item.context="{ item, header }">
            <div :title="item.context">
              <ETruncatedDiv :width="header.width ?? 350">{{
                item.context
              }}</ETruncatedDiv>
            </div>
          </template>
        </ReportTable>
      </ECol>
    </ERow>

    <v-dialog
      v-model="dialog"
      scrollable
      :max-width="dialogWidth"
      content-class="copilot-conversations__messages"
      transition="scroll-y-transition"
    >
      <v-card
        v-if="selectedConversation && messages.length"
        class="e-rounded-lg w-100 pa-0"
        height="auto"
      >
        <v-card-text class="d-flex flex-column pa-0">
          <EChat
            :messages="messages"
            :user="selectedConversation.user"
            :header-text="conversationTitle"
            inline-footer
            readonly
            embedded
          >
            <template #message.extraContent="{ message }">
              <div class="feedback-container d-flex flex-column">
                <v-slide-x-transition
                  v-for="feedback in feedbackByMessageId[message.id]"
                  :key="feedback.id"
                >
                  <div
                    v-if="!hiddenFeedback[feedback.id]"
                    class="copilot-conversations__message-feedback pl-4 pr-2 py-2 ml-2 my-2 d-flex e-rounded-md e-bg-gray-100"
                    :class="`feedback--${feedback.type}`"
                  >
                    <div>
                      {{ feedback.text }}
                    </div>
                    <v-btn
                      class="e-bg-gray-200 mb-auto ml-2"
                      icon
                      x-small
                      @click="hideFeedback(feedback)"
                    >
                      <v-icon> fa-times </v-icon>
                    </v-btn>
                  </div>
                </v-slide-x-transition>
              </div>
            </template>
          </EChat>

          <CopilotMessageStepsDiagram
            v-if="messageSteps"
            :steps="messageSteps"
            :copilot-provider="copilotProvider"
            @close="messageSteps = null"
          />
        </v-card-text>
        <ETimeline
          v-if="showTimeline"
          :start-date="selectedConversation.startDate"
          :end-date="selectedConversation.endDate"
          :min-date="messageTimelineDomain.startDate"
          :max-date="messageTimelineDomain.endDate"
          :selected-timestamp="messages[messages.length - 1]?.timestamp"
          :events-groups="messagesTimelineGroups"
          :markers="messagesFeedbackMarkers"
          class="w-100"
          :show-labels="false"
          @event-clicked="onTimelineMessageClicked"
          @marker-clicked="onFeedbackMarkerClicked"
        >
          <template #eventTooltip="{ event, active }">
            <div
              v-if="active"
              class="copilot-conversations__messages__tooltip pa-2 px-3 e-rounded-md"
            >
              <div class="d-flex">
                <strong class="mr-2">Content: </strong>
                <ETruncatedDiv :width="110">
                  {{ event.content }}
                </ETruncatedDiv>
              </div>
              <div class="d-flex">
                <strong class="mr-2">Time: </strong>
                {{ $moment(event.timestamp).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
          </template>
        </ETimeline>
      </v-card>
      <v-card
        v-if="selectedConversation && !messages.length"
        class="e-rounded-lg"
      >
        <v-card-title> Empty chat session </v-card-title>

        <div class="px-6 pb-6">
          <div class="d-flex pb-1">
            <strong class="pr-2">User:</strong>
            <div>{{ selectedConversation.user }}</div>
          </div>
          <div class="d-flex py-1">
            <strong class="pr-2">Start date:</strong>
            <div>{{ selectedConversation.startDate }}</div>
          </div>
          <div class="d-flex py-1">
            <strong class="pr-2">End date:</strong>
            <div>{{ selectedConversation.endDate }}</div>
          </div>
          <div class="pt-2 py-1">
            Possible scenarios:
            <ul>
              <li>The user opened Copilot but didn't send any message.</li>
              <li>The app crashed (frontend or backend).</li>
              <li>The internet connection was lost.</li>
            </ul>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import ReportTable from "@/components/ReportTable"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { useUserStore } from "@/stores/users"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { FeedbackType, TimelineChartType } from "@evercam/ui"
import { CopilotMessageAuthor, CopilotProvider } from "@evercam/shared/types"
import CopilotMessageStepsDiagram from "~/components/copilot/CopilotMessageStepsDiagram.vue"
import * as d3 from "d3"

export default {
  name: "CopilotMessagesHistory",
  components: {
    CopilotMessageStepsDiagram,
    ReportTable,
  },
  data() {
    return {
      messageSteps: null,
      showTimeline: false,
      headers: [
        {
          text: "User",
          value: "user",
          sortable: true,
          filterable: true,
          width: 200,
        },
        { text: "Project", value: "projectExid", sortable: true, width: 90 },
        { text: "Camera", value: "cameraExid", sortable: true, width: 90 },
        { text: "Messages", value: "messagesCount", sortable: true, width: 70 },
        { text: "Duration", value: "duration", sortable: false },
        { text: "Model", value: "model", sortable: true, width: 85 },
        {
          text: "Input tokens",
          value: "inputTokensCount",
          sortable: true,
          width: 70,
        },
        {
          text: "Output tokens",
          value: "outputTokensCount",
          sortable: true,
          width: 70,
        },
        {
          text: "Estimated Cost",
          value: "costEstimation",
          sortable: true,
          width: 80,
        },
        { text: "Start Date", value: "startDate", sortable: true, width: 150 },
        { text: "End Date", value: "endDate", sortable: true, width: 150 },
        { text: "Feedback", value: "feedbackCount", sortable: true, width: 70 },
        { text: "Context", value: "context", sortable: true },
      ],
      copilotFilterFields: {
        user: {
          component: "TextFieldSearchFilter",
        },
      },
      dialog: false,
      messages: null,
      selectedConversation: null,
      hiddenFeedback: {},
      conversations: [],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore),
    messageTimelineDomain() {
      return {
        start: new Date(
          new Date(this.selectedConversation.startDate).getTime() - 60_000
        ).toISOString(),
        end: new Date(
          new Date(this.selectedConversation.endDate).getTime() + 60_000
        ).toISOString(),
      }
    },
    copilotProvider() {
      return this.selectedConversation?.model.includes(CopilotProvider.Gemini)
        ? "Gemini"
        : "ChatGPT"
    },
    conversationTitle() {
      const conversationDate = this.$moment(
        this.selectedConversation.startDate
      ).format("MMM Do YYYY")

      const conversationStartTime = this.$moment(
        this.selectedConversation.startDate
      ).format("HH:mm:ss")

      const conversationEndTime = this.$moment(
        this.selectedConversation.endDate
      ).format("HH:mm:ss")

      return `(${this.selectedConversation.user}) - ${conversationDate} - from ${conversationStartTime} to ${conversationEndTime} - ${this.selectedConversation?.model}`
    },
    messagesTimelineGroups() {
      return {
        chatMessages: {
          label: "",
          color: "#aaa",
          chartType: TimelineChartType.Dots,
          dotsSize: 20,
          height: 45,
          events: this.messages.map((m) => ({
            ...m,
            timestamp: m.timestamp,
            color:
              m.role === CopilotMessageAuthor.Copilot
                ? TimelineColors.copilot
                : TimelineColors.user,
          })),
        },
      }
    },
    messagesFeedbackMarkers() {
      return this.messages.reduce((acc, m) => {
        if (!m.feedback?.length) {
          return acc
        }

        return [
          ...acc,
          ...m.feedback.map((f) => ({
            ...f,
            messageId: m.id,
            timestamp: f.createdAt,
            label: f.type === FeedbackType.Positive ? "👍" : "👎",
            color:
              f.type === FeedbackType.Positive
                ? "rgb(5, 150, 105)"
                : "rgb(239, 68, 68)",
            textColor: "#fff",
            isDraggable: false,
            className: "copilot-conversations__messages__feedback",
          })),
        ]
      }, [])
    },
    dialogWidth() {
      if (!this.selectedConversation?.messagesCount) {
        return 400
      } else {
        return 950
      }
    },
    feedbackByMessageId() {
      return this.messages.reduce((acc, m) => {
        if (!m.feedback?.length) {
          return acc
        }

        return {
          ...acc,
          [m.id]: m.feedback,
        }
      }, {})
    },
    minMaxValues() {
      const initialStats = {
        inputTokensCount: { min: Infinity, max: -Infinity },
        outputTokensCount: { min: Infinity, max: -Infinity },
        costEstimation: { min: Infinity, max: -Infinity },
      }

      return this.conversations.reduce((acc, item) => {
        if (item.inputTokensCount !== undefined) {
          acc.inputTokensCount.min = Math.min(
            acc.inputTokensCount.min,
            item.inputTokensCount
          )
          acc.inputTokensCount.max = Math.max(
            acc.inputTokensCount.max,
            item.inputTokensCount
          )
        }
        if (item.outputTokensCount !== undefined) {
          acc.outputTokensCount.min = Math.min(
            acc.outputTokensCount.min,
            item.outputTokensCount
          )
          acc.outputTokensCount.max = Math.max(
            acc.outputTokensCount.max,
            item.outputTokensCount
          )
        }
        if (item.costEstimation !== undefined) {
          acc.costEstimation.min = Math.min(
            acc.costEstimation.min,
            item.costEstimation
          )
          acc.costEstimation.max = Math.max(
            acc.costEstimation.max,
            item.costEstimation
          )
        }

        return acc
      }, initialStats)
    },
  },
  watch: {
    dialog(v) {
      if (!v) {
        this.showTimeline = false
        this.messageSteps = null
      } else {
        this.$setTimeout(() => {
          this.showTimeline = true
        }, 200)
      }
    },
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    async fetchConversations({ params }) {
      try {
        return await EvercamLabsApi.copilot.getAllConversations(params)
      } catch (error) {
        console.error("Failed to fetch conversations:", error)

        return {
          items: [],
          total: 0,
        }
      }
    },
    async viewConversation(conversation) {
      try {
        const { items } = await EvercamLabsApi.copilot.getConversationMessages(
          conversation.id,
          {
            page: 1,
            limit: 200,
          }
        )

        this.selectedConversation = conversation
        this.messages = items
        this.dialog = true
      } catch (error) {
        console.error("Failed to fetch messages:", error)
      }
    },
    async viewMessageSteps(message) {
      try {
        this.messageSteps = await EvercamLabsApi.copilot.getMessageSteps(
          message
        )
      } catch (error) {
        console.error("Failed to fetch messages:", error)
      }
    },
    formatDate(date) {
      return this.$moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    onTimelineMessageClicked({ event }) {
      this.messageSteps = null
      const { id } = event
      this.highlightMessage(id)

      if (event.role === CopilotMessageAuthor.Copilot) {
        this.viewMessageSteps(event)
      }
    },
    highlightMessage(id) {
      const messageElement = this.getTargetMessageElement(id)
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: "smooth" })
        messageElement.classList.add(
          "copilot-conversations__messages__message--highlight"
        )
        this.$setTimeout(() => {
          messageElement.classList.remove(
            "copilot-conversations__messages__message--highlight"
          )
        }, 2500)
      }
    },
    getTargetMessageElement(id) {
      return document.querySelector(`[data-message-id="${id}"]`)
    },
    onFeedbackMarkerClicked(feedback) {
      this.highlightMessage(feedback.messageId)
      this.hiddenFeedback[feedback.id] = false
    },
    hideFeedback(feedback) {
      this.hiddenFeedback = {
        ...this.hiddenFeedback,
        [feedback.id]: true,
      }
    },
    getConversationDuration(conversation) {
      const start = this.$moment(conversation.startDate)
      const end = this.$moment(conversation.endDate)
      const duration = this.$moment.duration(end.diff(start))
      const minutes = duration.minutes()
      const seconds = duration.seconds()

      if (minutes >= 1) {
        return `${minutes}m ${seconds}s`
      } else {
        return `${seconds}s`
      }
    },
    getColor(value, type) {
      if (value === undefined) {
        return
      }
      const { min, max } = this.minMaxValues[type]

      const colorScale = d3
        .scaleLinear()
        .domain([min, (min + max) / 2, max])
        .range(["rgb(75,147,15)", "rgb(231,38,38)"])
        .interpolate(d3.interpolateRgb)

      return colorScale(value)
    },
  },
}
</script>

<style lang="scss">
$positive-color: rgb(5, 150, 105);
$negative-color: rgb(239, 68, 68);

.copilot-conversations {
  .admin-data-table {
    tbody tr {
      cursor: pointer;
    }
  }
  &__message-feedback {
    height: fit-content;
    max-width: 500px;
    display: block;
    font-weight: 500;
    font-style: italic;
    position: sticky;
    top: 20px;
    right: 0;
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 8px solid #f3f4f6;
      left: -11px;
      top: 13px;
    }
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      left: -9px;
      top: 13px;
    }
    &.feedback--positive {
      color: $positive-color;
      border: 1px solid $positive-color;
      &:before {
        border-right: 11px solid #059669;
      }
      &:after {
        border-right: 10px solid #f3f4f6;
      }
    }
    &.feedback--negative {
      color: $negative-color;
      border: 1px solid $negative-color;
      &:before {
        border-right: 11px solid rgb(239, 68, 68);
      }
      &:after {
        border-right: 10px solid #f3f4f6;
      }
    }
  }
}

.feedback-container {
  align-items: baseline;
  position: sticky;
  top: 20px;
  right: 0;
  height: fit-content;
}
.copilot-conversations__messages {
  overflow: visible;
  .e-timeline__svg-container {
    border-radius: 0;
    border-top: 1px solid #e8e8e8;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &__tooltip {
    height: 54px;
    width: 190px;
    background: rgb(243, 244, 246);
    transform: translate(-50%, -10%);
  }
  .e-chat-wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
  }
  .e-chat-message {
    transition: background-color 0.2s;
  }
  .copilot-conversations__messages__message--highlight {
    background-color: rgba(24, 103, 192, 0.1);
    border-radius: 10px;
    animation: flash 2s ease-in-out forwards;
  }

  @keyframes flash {
    0%,
    50%,
    100% {
      background-color: transparent;
    }
    25%,
    75% {
      background-color: rgba(24, 103, 192, 0.1);
    }
  }
}
</style>
