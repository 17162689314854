<template>
  <div class="copilot-message-steps position-relative">
    <div
      ref="diagramContainer"
      class="copilot-message-steps__diagram pt-2 white"
    ></div>
    <v-btn
      class="copilot-message-steps__close-btn"
      icon
      @click="$emit('close')"
    >
      <v-icon> fa-times </v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import Mermaid from "mermaid"
import { CopilotMessageStep, CopilotStepId } from "@evercam/shared/types"

export default Vue.extend({
  name: "CopilotMessageStepsDiagram",
  components: {},
  props: {
    steps: {
      type: Array as PropType<CopilotMessageStep[]>,
      required: true,
    },
    copilotProvider: {
      type: String,
      required: true,
    },
  },
  computed: {
    mermaidDiagramString() {
      const entityMap = {
        [CopilotStepId.SendToLLM]: this.copilotProvider,
        [CopilotStepId.FunctionCallRequest]: "System",
        [CopilotStepId.ExecFunctionCall]: "System",
        [CopilotStepId.SendToUser]: "User",
        [CopilotStepId.RequestMissingFields]: "User",
        [CopilotStepId.CompletedFieldsResponse]: "System",
        [CopilotStepId.SubmitToolOutputsToLLM]: this.copilotProvider,
        [CopilotStepId.CancelCall]: this.copilotProvider,
        [CopilotStepId.SendRawToolCallResponse]: "User",
        default: "System",
      }

      let diagram = `sequenceDiagram
        autonumber
        participant User
        participant System
        participant ${this.copilotProvider}

      `

      let index = 0
      for (let step of this.steps) {
        const isFunctionCallResult =
          index > 0 && step.step === CopilotStepId.SendToLLM
        const entity = entityMap[step.step] || entityMap.default

        let args = ""
        if (step.args.length > 0) {
          args =
            step.args
              .map((arg) =>
                this.sanitizeAndTrimString(arg, 54, isFunctionCallResult)
              )
              .join(" ") + "..."
        }

        if (index === 0) {
          diagram += `  User->>${this.copilotProvider}: Ask: "${args}"\n`
        } else if (step.step === CopilotStepId.SendToLLM) {
          diagram += `  System-->>${this.copilotProvider}: Provide data: "${args}"\n`
        } else if (step.step === CopilotStepId.FunctionCallRequest) {
          diagram += `  ${this.copilotProvider}-->>System: Request a function call: ${args}\n`
        } else if (step.step === CopilotStepId.ExecFunctionCall) {
          diagram += `  System-->>System: Execute function call: [${args}]\n`
        } else if (step.step === CopilotStepId.SendToUser) {
          diagram += `  ${this.copilotProvider}->>User: Response: "${args}"\n`
        } else if (step.step === CopilotStepId.RequestMissingFields) {
          diagram += `  System->>User: request to provide missing fields: "${args.replaceAll(
            /name\s+([a-zA-Z]+)\s+type\s+\S+\s+error\s+\S+/g,
            " '$1', "
          )}"\n`
        } else if (step.step === CopilotStepId.CompletedFieldsResponse) {
          diagram += `  User->>System: Provide missing fields: "${step.args}"\n`
        } else if (step.step === CopilotStepId.SubmitToolOutputsToLLM) {
          diagram += `  System->>${this.copilotProvider}: Submit tool outputs: "${args}"\n`
        } else if (step.step === CopilotStepId.CancelCall) {
          diagram += `  System->>${this.copilotProvider}: Cancel Call\n`
        } else if (step.step === CopilotStepId.SendRawToolCallResponse) {
          diagram += `  System->>User: Sent tool call response: ${args}\n`
        } else {
          diagram += `  ${entity}-->>${entity}: ${args}\n`
        }

        index++
      }

      return diagram
    },
  },
  mounted() {
    Mermaid.initialize({ startOnLoad: false })
    this.renderDiagram()
  },
  methods: {
    async renderDiagram() {
      const element = this.$refs.diagramContainer as HTMLElement
      const { svg, bindFunctions } = await Mermaid.render(
        "graphDiv",
        this.mermaidDiagramString
      )
      element.innerHTML = svg
      bindFunctions?.(element)
    },
    sanitizeAndTrimString(str, maxLength, isFunctionCallResult) {
      let shiftedString = str

      if (isFunctionCallResult && str.length > 120) {
        shiftedString = str.substring(120)
      }

      return shiftedString
        .replace(/[^a-zA-Z0-9 ]/g, " ")
        .substring(0, maxLength)
        .trim()
    },
  },
})
</script>

<style lang="scss">
.copilot-message-steps {
  border-top: 1px solid var(--dash-border-color);
  &__close-btn {
    position: absolute;
    top: -36px;
    right: 24px;
    background: #eee;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid var(--dash-border-color);
    border-right: 1px solid var(--dash-border-color);
    border-left: 1px solid var(--dash-border-color);
  }
}
</style>
